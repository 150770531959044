import { Alert } from '@mui/material';
import { FC } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { FullscreenStatus } from '../../../ui';
import { PatientContainer } from '../../containers';
import { usePatient } from '../../hooks';
import { PatientScansScreen } from '../PatientScansScreen';
import { PatientScreen } from '../PatientScreen';
import { ScreenTtile } from './styles';

export const PatientScreenRouter: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [data, loading, error, reload] = usePatient(id);

  if (!data && loading) {
    return <FullscreenStatus loading />;
  }

  if (error || !data) {
    return <Alert color='error'>{error?.message || 'No data'}</Alert>;
  }

  return (
    <PatientContainer patient={data} reload={reload}>
      <DocumentTitle title={`${data.first_name} ${data.last_name}`} />
      <ScreenTtile variant='h1'>
        {data.first_name} {data.last_name}
      </ScreenTtile>
      <Routes>
        <Route index element={<PatientScreen />} />
        <Route path='scans' element={<PatientScansScreen />} />
      </Routes>
    </PatientContainer>
  );
};
