import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { InvitePatientWidget, PatientCountWidget } from '../../../patient';
import { LastScansWidget, ScanCountWidget } from '../../../scan';
import { LastScansContainer } from './styles';
import { SignupRequestsTable } from '../../../signupRequests/components';

export const DashboardScreen: FC = () => {
  return (
    <div>
      <div>
        <DocumentTitle title='Dashboard' />
        <Typography variant='h1'>Dashboard</Typography>
      </div>
      <Grid container spacing={4} alignItems='stretch' mt={0}>
        <Grid item xs={5}>
          <ScanCountWidget />
        </Grid>
        <Grid item xs={3.5}>
          <PatientCountWidget />
        </Grid>
        <Grid item xs={3.5}>
          <InvitePatientWidget />
        </Grid>
        <Grid item xs={12}>
          <SignupRequestsTable />
        </Grid>
        <Grid item xs={12}>
          <LastScansContainer>
            <LastScansWidget />
          </LastScansContainer>
        </Grid>
      </Grid>
    </div>
  );
};
