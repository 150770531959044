import { FC, ReactNode } from 'react';
import { Box, Link, Typography } from '@mui/material';
import secondaryLogo from '../../assets/image/icons/secondaryLogo.svg';
import { ContentWrapper, Header, Logo, ScreenWrapper } from './styles';
import { useNavigate } from 'react-router-dom';

export const UnauthorizedLayout: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();

  const handleContactUs = () => {
    navigate('/contact_us');
  };

  return (
    <ScreenWrapper>
      <Header>
        <Box>
          <Link href='https://seespine.ca' target='_blank'>
            <Logo src={secondaryLogo} />
          </Link>
        </Box>
        <Box>
          <Link onClick={handleContactUs}>
            <Typography variant='button'>Contact us</Typography>
          </Link>
        </Box>
      </Header>
      <ContentWrapper>{children}</ContentWrapper>
    </ScreenWrapper>
  );
};
