import {
  PerspectiveCamera,
  Mesh,
  Scene,
  WebGLRenderer,
  MeshPhongMaterial,
  Group,
  Box3,
  Vector3,
  BoxGeometry,
  MeshBasicMaterial,
  PlaneGeometry,
  AmbientLight,
  DirectionalLight,
  PCFSoftShadowMap,
  Color,
  Fog,
  DoubleSide,
  TextureLoader,
  Texture
} from 'three';
import { OrbitControls } from '@three-ts/orbit-controls';
// @ts-ignore
import { PLYLoader } from 'three/addons/loaders/PLYLoader.js';
import { Listener } from '../../../../../utils/Listener';
import { authService } from '../../../../auth';
import { MeshMetaData } from '.';
import {
  loadJSONData,
  create3DPlusSymbol,
  create3DSpineSegment
} from './MeshLoadingHelpers';
import { type PreloadUrl } from './types';
import { SyncedOrbitControlsInstance } from '../../ScanCompare/ScanSync';

interface cachedMeshData {
  mesh: Mesh;
  bent_deviation_mesh: Mesh | null;
  metadata: MeshMetaData | null;
}

const meshCache: { [url: string]: Promise<cachedMeshData> } = {};

interface Meshes {
  [key: string]: Mesh | Group | DirectionalLight | AmbientLight;
}

interface LayersState {
  torso_mesh: boolean;
  bent_deviation_mesh: boolean;
  mid_sac_plane: boolean;
  shoulders: boolean;
  pseudo_spine: boolean;
  trunk_shift_plane: boolean;
  plumb_shift_plane: boolean;
}

export interface MeshServiceOptions {
  container: HTMLElement;
  url?: string;
}

export class MeshService extends Listener {
  camera: PerspectiveCamera;
  scene: Scene;
  renderer: any;
  controls: any;

  meshes: Meshes | null = null;

  container: HTMLElement;
  url?: string | null = null;
  layers: LayersState;
  syncName?: 'first' | 'second' | undefined;

  constructor(
    options: MeshServiceOptions,
    layers: LayersState,
    syncName?: 'first' | 'second'
  ) {
    super();
    this.syncName = syncName;
    const { container } = options;
    if (!container) {
      throw new Error('Container is not proided');
    }
    this.container = container;
    this.layers = layers;

    const width = container.offsetWidth;
    const height = container.offsetHeight;

    // Init renderer
    this.renderer = new WebGLRenderer({ antialias: true });
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = PCFSoftShadowMap;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(width, height);
    container.appendChild(this.renderer.domElement);

    // Init scene
    this.scene = new Scene();
    this.scene.background = new Color('darkgrey');
    this.scene.fog = new Fog(0xa0a0a0, 10, 40);

    // Init camera
    this.camera = new PerspectiveCamera(30, width / height, 0.01, 40);
    this.camera.position.set(0, 1.5, 3);
    this.scene.add(this.camera);

    // Init controls
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    this.controls.minDistance = 0.5;
    this.controls.maxDistance = 100;

    this.render();
    this.initEvents();
  }

  updateLayers(layers: LayersState) {
    this.layers = layers;

    Object.keys(layers).forEach(key => {
      const layerKey = key as keyof LayersState;
      if (this.meshes && this.meshes[layerKey]) {
        this.meshes[layerKey].visible = layers[layerKey];
      }
    });

    this.render();
  }

  updateLayersVisibility() {
    Object.keys(this.layers).forEach(key => {
      const layerKey = key as keyof LayersState;
      if (this.meshes && this.meshes[layerKey]) {
        this.meshes[layerKey].visible = this.layers[layerKey];
      }
    });
  }

  initEvents = () => {
    if (this.syncName !== undefined) {
      SyncedOrbitControlsInstance.setOrbitControls(
        this.controls,
        this.syncName
      );
    }
    this.controls.addEventListener('change', this.render);
    window.addEventListener('resize', this.resize);
  };

  private async fetchModelData(
    url: string,
    token: string
  ): Promise<ArrayBuffer> {
    const isS3Request = url ? url.includes('stream_s3_resource') : false;
    const headers = isS3Request
      ? {
          Authorization: 'Bearer ' + token,
        }
      : undefined;
    try {
      const response = await fetch(url, {headers});
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.arrayBuffer();
    } catch (error) {
      console.error('A problem occurred with the fetch operation: ', error);
      throw error;
    }
  }

  private loadModelByUrl = async (
    url: string,
    deviation_url: string | null,
    texture_url: string | null,
    mesh_metadata: MeshMetaData | null
  ): Promise<cachedMeshData> => {
    const accessToken = await authService.getValidAccessToken();
    const token = accessToken;
    const arrayBuffer = await this.fetchModelData(url,token);
    const loader = new PLYLoader();
    const geometry = loader.parse(arrayBuffer);
    geometry.computeVertexNormals();

    let texture: Texture | null = null;
    let material: MeshPhongMaterial = new MeshPhongMaterial();

    const nonTextureMaterial = new MeshPhongMaterial({
      color: 0xffffff,
      flatShading: true,
      vertexColors: true,
      shininess: 0
    });

    if (texture_url !== null) {
      const arrayBuffer = await this.fetchModelData(texture_url,token);
      const blob = new Blob([arrayBuffer]);
      const textureBlobUrl = URL.createObjectURL(blob);

      const textureLoader = new TextureLoader();
      texture = await textureLoader.loadAsync(textureBlobUrl);

      material = new MeshPhongMaterial({
        map: texture
      });
    } else {
      material = nonTextureMaterial;
    }

    let bent_deviation_mesh: Mesh | null = null;

    if (deviation_url !== null) {
      const deviationArrayBuffer = await this.fetchModelData(
        deviation_url, token
      );
      const deviationGeometry = loader.parse(deviationArrayBuffer);
      deviationGeometry.computeVertexNormals();

      bent_deviation_mesh = new Mesh(deviationGeometry, nonTextureMaterial);
    }

    const torso_mesh = new Mesh(geometry, material);

    return {
      mesh: torso_mesh,
      bent_deviation_mesh: bent_deviation_mesh,
      metadata: mesh_metadata
    };
  };

  loadModel = async (
    url: string,
    deviation_url: string | null,
    texture_url: string | null,
    mesh_metadata: MeshMetaData | null
  ) => {
    this.url = url;
    this.trigger('loadStart', null);
    if (url in meshCache) {
      const cachedMesh = await meshCache[url];
      this.setMeshes(
        cachedMesh.mesh.clone(),
        cachedMesh.bent_deviation_mesh
          ? cachedMesh.bent_deviation_mesh.clone()
          : null,
        cachedMesh.metadata
      );
      this.trigger('loadEnd', null);
      return;
    }
    meshCache[url] = this.loadModelByUrl(
      url,
      deviation_url,
      texture_url,
      mesh_metadata
    ).then((cachedMeshData: cachedMeshData) => {
      this.setMeshes(
        cachedMeshData.mesh,
        cachedMeshData.bent_deviation_mesh,
        cachedMeshData.metadata
      );
      this.trigger('loadEnd', null);
      return cachedMeshData;
    });
  };

  addUrlsToCache = async (urls: PreloadUrl[]) => {
    await Promise.all(
      urls.map(
        ({ mesh_url, deviation_mesh_url, mesh_texture_url, mesh_metadata }) => {
          if (!(mesh_url in meshCache)) {
            return (meshCache[mesh_url] = this.loadModelByUrl(
              mesh_url,
              deviation_mesh_url,
              mesh_texture_url,
              mesh_metadata
            )).catch(error => {
              console.error(`Failed to load model by URL: ${mesh_url}`, error);
              return null;
            });
          } else {
            return Promise.resolve();
          }
        }
      )
    );
  };

  setMeshes = (
    torso_mesh: Mesh,
    bent_deviation_mesh: Mesh | null,
    mesh_metadata: MeshMetaData | null
  ) => {
    let meshes: Meshes = {};

    // Adjust the camera's position to make sure the entire model is visible
    const boundingBox = new Box3().setFromObject(torso_mesh);
    let { x: centerX, y: centerY, z: centerZ } = boundingBox.getCenter(
      new Vector3()
    ); // get the center of the bounding box
    this.camera.position.set(0, centerY, 3); // sets the height of the camera to be be at the center of the bounding box
    this.controls.target.set(centerX, centerY, centerZ); // sets the center of the pivot point of the orbit control
    this.controls.update(); // needed to update the orbit controls before user interaction so that there's no jump

    const size = boundingBox.getSize(new Vector3());

    torso_mesh.castShadow = true; // this mesh will cast shadows
    torso_mesh.receiveShadow = true;

    // Store this mesh in our meshes object
    meshes.torso_mesh = torso_mesh;

    if (bent_deviation_mesh !== null) {
      bent_deviation_mesh.castShadow = true;
      bent_deviation_mesh.receiveShadow = true;
      bent_deviation_mesh.visible = false;

      meshes.bent_deviation_mesh = bent_deviation_mesh;
    }

    // Create a scale bar that is 10 cm long and 10 cm to the right of the bounding box
    const scaleBarLength = 0.1; // 10cm
    const scaleBarGeometry = new BoxGeometry(scaleBarLength, 0.005, 0.005);
    const scaleBarMaterial = new MeshBasicMaterial({ color: 0x000000 });
    const scaleBar = new Mesh(scaleBarGeometry, scaleBarMaterial);

    // Position the scale bar at the bottom right (adjust these values as needed)
    // Extract the bottom right coordinate of the bounding box
    const bottomRight = boundingBox.max; // `max` gives the upper-right-front corner of the bounding box

    // Adjust the position to move the scale bar 10cm to the right and to the bottom edge
    bottomRight.x += 0.1 + scaleBarLength / 2; // shift it 10cm + half the scale bar's length
    bottomRight.y = boundingBox.min.y; // set it to the bottom edge of the bounding box

    // Set the scale bar's position
    scaleBar.position.set(bottomRight.x, bottomRight.y, bottomRight.z);

    // Create a large plane geometry for the floor
    const floorGeometry = new PlaneGeometry(1000, 1000); // Adjust size as needed

    // Create a white material for the floor
    const floorMaterial = new MeshPhongMaterial({
      color: 'white'
    });

    // Create the floor mesh
    const floor = new Mesh(floorGeometry, floorMaterial);

    floor.rotateX(-Math.PI / 2);

    const torsoMeshBoundingBox = new Box3().setFromObject(meshes.torso_mesh);

    floor.position.y = torsoMeshBoundingBox.min.y;
    floor.receiveShadow = true;
    floor.castShadow = true;

    // Add the floor to your meshes
    meshes.floor = floor;

    const ambientLight = new AmbientLight(0xffffff, 0.9); // adjust the intensity as needed
    meshes.ambientLight = ambientLight;

    // Init directional light
    const directionalLight = new DirectionalLight();
    directionalLight.position.set(1, 1, 1);
    directionalLight.castShadow = true;
    directionalLight.intensity = 0.3;

    meshes.directionalLight = directionalLight;

    if (mesh_metadata !== null) {
      const data = loadJSONData(mesh_metadata);
      const hipCentroid =
        data.centroid_coordinates.length > 0
          ? data.centroid_coordinates[0].z
          : 0;

      const thickness = 0.005; // Set this to your desired thickness
      const planeHeight = size.y * 2; // Double of the mesh's height to ensure it covers everything
      const planeDepth = size.z * 2; // Double of the mesh's depth
      const alignPlaneGeometry = new BoxGeometry(
        thickness,
        planeHeight,
        planeDepth
      );

      // Create a blue plane material
      const bluePlaneMaterial = new MeshBasicMaterial({
        color: 0x0000ff,
        transparent: true,
        opacity: 0.75
      });

      // Create the mid sac plane
      const greenPlaneMaterial = new MeshBasicMaterial({
        color: 0x00ff00,
        transparent: true,
        opacity: 0.75
      });

      // Yellow plane
      const yellowPlaneMaterial = new MeshBasicMaterial({
        color: 0xffff00, // Yellow color
        transparent: true,
        opacity: 0.75
      });

      // CREATE THE MID SAC LINE PLANE
      const mid_sac_mesh = new Mesh(alignPlaneGeometry, greenPlaneMaterial);
      mid_sac_mesh.position.x = data.mid_sac_plane;
      mid_sac_mesh.position.y = centerY;
      mid_sac_mesh.position.z = hipCentroid;
      meshes.mid_sac_plane = mid_sac_mesh;

      // CREATE THE TRUNK SHIFT PLANE
      const trunk_shift_plane = new Mesh(alignPlaneGeometry, bluePlaneMaterial);
      trunk_shift_plane.position.x = data.trunk_shift_plane;
      trunk_shift_plane.position.y = centerY;
      trunk_shift_plane.position.z = hipCentroid;
      meshes.trunk_shift_plane = trunk_shift_plane;

      // CREATE THE PLUMB LINE PLANE
      const plumb_shift_plane = new Mesh(
        alignPlaneGeometry,
        yellowPlaneMaterial
      );
      plumb_shift_plane.position.x = data.plumb_shift_plane;
      plumb_shift_plane.position.y = centerY;
      plumb_shift_plane.position.z = hipCentroid;
      meshes.plumb_shift_plane = plumb_shift_plane;

      // Create 3D plus symbols for the shoulder coordinates
      const shoulders = new Group();
      const shoulder1Plus = create3DPlusSymbol(data.shoulder1_coordinates);
      const shoulder2Plus = create3DPlusSymbol(data.shoulder2_coordinates);
      shoulders.add(shoulder1Plus, shoulder2Plus);

      // Extract the shoulder points
      const shoulder1 = new Vector3(
        data.shoulder1_coordinates.x,
        data.shoulder1_coordinates.y,
        data.shoulder1_coordinates.z
      );
      const shoulder2 = new Vector3(
        data.shoulder2_coordinates.x,
        data.shoulder2_coordinates.y,
        data.shoulder2_coordinates.z
      );

      // Calculate the midpoint
      const midpoint = new Vector3()
        .addVectors(shoulder1, shoulder2)
        .multiplyScalar(0.5);

      // Calculate the distance between the points
      const distance = shoulder1.distanceTo(shoulder2);

      // Create the plane geometry. Let's assume you want a thickness of 0.1. Adjust as needed.
      const shoulderPlaneGeometry = new PlaneGeometry(distance, 0.002);

      // Create the material
      const shoulderPlaneMaterial = new MeshBasicMaterial({
        color: 0xff0000,
        side: DoubleSide
      });

      // Create the plane mesh
      const planeMesh = new Mesh(shoulderPlaneGeometry, shoulderPlaneMaterial);

      // Position the mesh at the midpoint
      planeMesh.position.copy(midpoint);

      planeMesh.rotateX(Math.PI); // Rotate the plane by 90 degrees on the X-axis to align it

      // Finally, add to your shoulders group
      shoulders.add(planeMesh);
      //scene.add(shoulders); // Add the whole group to the scene

      meshes.shoulders = shoulders; // Assign the shoulders group to meshes.shoulders

      // Create the 3D pseudo spine
      const spine = new Group();

      if (data.psedo_spine?.length) {
        for (let i = 0; i < data.psedo_spine.length; i++) {
          let vertebraePos = data.psedo_spine[i];

          let rotationAmount = i === 0 ? 0 : data.layer_rotations[i - 1]; // Use i-1 because layer_rotations is one element shorter

          const vertebrae = create3DSpineSegment(
            vertebraePos,
            rotationAmount,
            data.slice_thickness[i]
          );

          spine.add(vertebrae);
        }
      }

      meshes.pseudo_spine = spine; // Assign the spine group to meshes.spine
    }

    this.meshes = meshes;

    this.scene.clear();
    Object.values(meshes).forEach(mesh => this.scene.add(mesh));
    this.updateLayersVisibility();
    this.render();
  };

  resize = () => {
    const width = this.container.offsetWidth;
    const height = this.container.offsetHeight;

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(width, height);
    this.render();
  };

  render = () => {
    this.renderer.render(this.scene, this.camera);
  };

  increaseMeshSize = () => {
    if (this.meshes) {
      // @ts-ignore
      this.meshes.material.size *= 1.2;
      this.render();
    }
  };

  decreaseMeshSize = () => {
    if (this.meshes) {
      // @ts-ignore
      this.meshes.material.size /= 1.2;
      this.render();
    }
  };
}
